import useAuth from "@/modules/auth/composables/useAuth";
import {Ref, ref} from "vue";
import {v4 as uuid} from "uuid";
import {AccountingAccountForm} from "@/modules/accountingAccounts/interfaces";
import store from "@/store";

const {companyId} = useAuth()
const defaultValues = (): AccountingAccountForm => {
    return {
        id: uuid(),
        name: "",
        code: "",
        description: "",
        status: "active",
        parentId: "",
        companyId: companyId.value
    }
}

const accountingAccountForm: Ref<AccountingAccountForm> = ref(defaultValues())

const useAccountingAccount = () => {
    const catalogs = {
        status: [
            {id: 'active', title: 'Activo'},
            {id: 'inactive', title: 'Inactivo'},
        ]
    };
    const clearForm = () => accountingAccountForm.value = defaultValues()

    const createAccoutingAccount = async(accountingAccountForm: AccountingAccountForm) => {
        return await store.dispatch('accountingAccounts/createAccountingAccount', accountingAccountForm)
    }

    const updateAccountingAccount = async (accountingAccountForm: AccountingAccountForm) => {
        return await store.dispatch('accountingAccounts/updateAccountingAccount', accountingAccountForm)
    }

    const getAccountingAccount = async (id: string): Promise<AccountingAccountForm> => {
        const {ok, data} = await store.dispatch('accountingAccounts/getAccountingAccount', id)

        return data;
    }

    return {
        accountingAccountForm,
        catalogs,
        clearForm,
        createAccoutingAccount,
        updateAccountingAccount,
        getAccountingAccount,
    }
}

export default useAccountingAccount;
