
import {onMounted, ref, watch} from "vue";
import useAccountingAccount from "@/modules/accountingAccounts/composables/useAccountingAccount";
import usePanel from "@/composables/usePanel";
import erpApi from "@/api/erpApi";
import toastr from "toastr";
import useAuth from "@/modules/auth/composables/useAuth";
import $ from "jquery";
import useSelect2 from "@/composables/useSelect2";


const {accountingAccountForm} = useAccountingAccount();


export default {
    props: ['catalogs'],
    setup() {
        const {companyId} = useAuth()
        const {showPanel, togglePanel} = usePanel()
        const {select2account} = useSelect2()
        const code = ref('')
        const name = ref('')
        const description = ref('')
        const status = ref('')
        const parentId = ref('')

        const onGeneratePlan = (companyId: string, selector = '.copyPlan2, #copyPlan2') => {
            const body = $('body');
            body.off('click', selector).on('click', selector, function (e) {
                e.preventDefault();
                erpApi.post('/accounting_accounts/generate_plan', {companyId}).then(() => {
                    toastr.success('Su solicitud se ha procesado correctamente.')
                }).catch((error) => {
                    toastr.error(error.response.data.message);
                });
            });
        }

        onMounted(() => {
            code.value = accountingAccountForm.value.code
            name.value = accountingAccountForm.value.name
            description.value = accountingAccountForm.value.description
            status.value = accountingAccountForm.value.status
            parentId.value = accountingAccountForm.value.parentId
            onGeneratePlan(companyId.value)
        })

        watch(code, val => accountingAccountForm.value.code = val)
        watch(name, val => accountingAccountForm.value.name = val)
        watch(description, val => accountingAccountForm.value.description = val)
        watch(status, val => accountingAccountForm.value.status = val)
        watch(parentId, val => accountingAccountForm.value.parentId = val)

        return {
            showPanel,
            togglePanel,
            code,
            name,
            description,
            status,
            parentId,
            select2account,
            accountingAccountForm,
            onGeneratePlan
        }
    }
}
